"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
function MessageBlock({ message }) {
    const [counter, setCounter] = (0, react_1.useState)(100);
    (0, react_1.useEffect)(() => {
        if (message) {
            const interval = setInterval(() => {
                if (counter > 0) {
                    setCounter(counter - 1);
                }
                else {
                    window.open('https://echo4.eu', '_self');
                }
            }, 80);
            return () => clearInterval(interval);
        }
        return () => { };
    }, [counter, message]);
    if (!message) {
        return null;
    }
    return (react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, vCenter: true, gap: 24 },
        react_1.default.createElement("h1", null,
            "\uD83C\uDF89",
            message),
        react_1.default.createElement("h3", null, "You will be redirected"),
        react_1.default.createElement(react_components_1.Usage, { value: counter, label: "", width: "100%", colors: ['#02802C', '#02802C', '#02802C'] })));
}
exports.default = MessageBlock;
MessageBlock.defaultProps = {
    message: null,
};
